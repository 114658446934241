import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import {
  ContentBox,
  Grid,
  HalvedContent,
  List,
  Paragraphs,
  SectionHead,
} from 'src/components';
import { Card, CardHeader } from 'src/components/Cards';
import MainLayout from 'src/layout/MainLayout';

import content from 'src/content/unsere-loesungen/absicherung-fuer-unternehmen/absicherung-ihres-unternehmens';

const AbsicherungIhresUnternehmensPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: '75',
    }}
  >
    <section id="keyperson-versicherung">
      <HalvedContent align="right">
        <ContentBox headline={content.keypersonVersicherungLinks.headline}>
          <Paragraphs
            items={content.keypersonVersicherungLinks.paragraphs}
            innerHTML
          />
        </ContentBox>
        <ContentBox
          headline={content.keypersonVersicherungRechts.headline}
          background="light-blue"
        >
          <Paragraphs
            items={content.keypersonVersicherungRechts.paragraphs}
            innerHTML
          />
        </ContentBox>
      </HalvedContent>
    </section>
    <section
      id="betriebsunterbrechungsmoeglichkeiten"
      className="no-children-margin"
    >
      <SectionHead
        headline={content.betriebsunterbrechungsversicherung.headline}
        centered
        gap
      >
        <Paragraphs
          items={content.betriebsunterbrechungsversicherung.paragraphs}
          innerHTML
        />
      </SectionHead>
      <HalvedContent align="center" swapped>
        <BackgroundImage fluid={data.manWithPenInHand.childImageSharp.fluid} />
        <ContentBox
          headline={
            content.betriebsunterbrechungsversicherung.halvedContent1.headline
          }
          background="light-grey-blue"
        >
          <p>
            {
              content.betriebsunterbrechungsversicherung.halvedContent1
                .paragraph
            }
          </p>
        </ContentBox>
      </HalvedContent>
      <HalvedContent align="center">
        <BackgroundImage
          fluid={data.manAndRedHairedWoman.childImageSharp.fluid}
        />
        <ContentBox
          headline={
            content.betriebsunterbrechungsversicherung.halvedContent2.headline
          }
          background="light-pink"
        >
          <p>
            {
              content.betriebsunterbrechungsversicherung.halvedContent2
                .paragraph
            }
          </p>
        </ContentBox>
      </HalvedContent>
    </section>
    <section>
      <SectionHead headline={content.existenz.headline} centered />
      <Grid>
        {content.existenz.cards.map((card, index) => (
          <Card key={index}>
            <CardHeader headline={card.headline} color="dark-blue" parallel>
              <card.icon />
            </CardHeader>
            <p>{card.paragraph}</p>
          </Card>
        ))}
      </Grid>
      <ContentBox
        headline={content.existenz.vorteileHeadline}
        background="light-blue"
        single
      >
        <List items={content.existenz.vorteile} columns={2} />
      </ContentBox>
    </section>
    <section id="liquidationsversicherung">
      <HalvedContent align="left">
        <BackgroundImage
          fluid={data.womanWithNewspaperLaughingOutside.childImageSharp.fluid}
        />
        <ContentBox headline={content.liquidationsversicherung.headline}>
          <Paragraphs
            items={content.liquidationsversicherung.paragraphs}
            innerHTML
          />
        </ContentBox>
      </HalvedContent>
      <ContentBox background="light-blue" single>
        <Paragraphs
          items={content.liquidationsversicherung.card.cardSections.paragraphs}
          innerHTML
        />

        <ol className="list-decimal list-inside">
          {content.liquidationsversicherung.card.cardSections.list.map(
            (listItem, index) => (
              <li key={index}>{listItem}</li>
            ),
          )}
        </ol>
        <Paragraphs
          items={content.liquidationsversicherung.card.cardSections.notice}
          innerHTML
        />
      </ContentBox>
      <ContentBox
        background="light-blue"
        headline={content.liquidationsversicherung.footNote.headline}
        single
      >
        <Paragraphs
          items={content.liquidationsversicherung.footNote.paragraphs}
          innerHTML
        />
      </ContentBox>
    </section>
  </MainLayout>
);

export default AbsicherungIhresUnternehmensPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-orange-hair-with-glases-wide.jpg" }
    ) {
      ...image
    }
    manWithPenInHand: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-with-pen-in-hand.jpg" }
    ) {
      ...image
    }
    manAndRedHairedWoman: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-and-red-haired-woman.jpg" }
    ) {
      ...image
    }
    womanWithNewspaperLaughingOutside: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-with-newspaper-laughing-outside.jpg" }
    ) {
      ...image
    }
    liquidIllu: file(
      sourceInstanceName: { eq: "illustrations" }
      relativePath: { eq: "liquiditationsversicherung.png" }
    ) {
      ...image
    }
  }
`;
