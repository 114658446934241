import HomeOfficeIcon from 'src/assets/icons/home-office.svg';
import MaintenanceIcon from 'src/assets/icons/maintenance.svg';

const content = {
  title: 'Absicherung Ihres Unternehmens',
  headvisualHeadline: 'Absicherung Ihres Unternehmens',
  keypersonVersicherungLinks: {
    headline: 'Keyperson-Versicherung',
    paragraphs: [
      '<strong>Versichern Sie die Unverzichtbaren!</strong>',
      'Ihre Mitarbeiter sind das größte Kapital Ihrer Firma. Die Schlüsselpositionen haben Sie mit Ihren besten Angestellten besetzt. Vielleicht sind Sie es aber auch selbst, an dem der Erfolg der Firma hängt? Alles läuft gut. Bis zu dem Moment, wenn eine Schlüsselperson im Unternehmen ausfällt – aufgrund einer schweren Erkrankung mit langer Genesungszeit oder Tod.',
    ],
  },
  keypersonVersicherungRechts: {
    paragraphs: [
      'Und es ist niemand da, der diese Lücke schließen kann. Die Folgen können vielfältig sein! Projekte können nicht fortgesetzt werden, es kommt zu Konventionalstrafen wegen Nichterfüllung von Verträgen, Aufträge gehen verloren, bis hin zur Insolvenz – verbunden mit der Arbeitslosigkeit für Ihre Beschäftigten.',
      'Von IT über Prozesssteuerung oder auch Management – es gibt Menschen, die für den Betrieb unverzichtbar sind. Das zeigt auch die Statistik: Jede 4. Insolvenz geht auf den Ausfall einer Schlüsselperson (Keyperson) zurück.',
      'Eine passende Versicherung kann Ihnen helfen, finanzielle Risiken, die mit dem Ausfall einer Keyperson verbunden sind, abzusichern.',
      '<strong>Hinweis:</strong> Die Keyperson-Versicherung wird derzeit nicht von der NÜRNBERGER Versicherung angeboten.',
    ],
  },
  betriebsunterbrechungsversicherung: {
    headline: 'Betriebsunterbrechungsversicherung',
    paragraphs: [
      '<strong>Damit die Existenz Ihres Betriebs gesichert ist - auch wenn Sie ausfallen.</strong>',
      'Als Selbstständiger tun Sie natürlich alles für Ihre Firma, in die Sie jede Menge Zeit, Geld und Herzblut gesteckt haben. Doch was, wenn Sie plötzlich krank werden oder einen Unfall haben? Wer sorgt dann für einen reibungslosen Betriebsablauf und ein gesichertes Einkommen?',
    ],
    halvedContent1: {
      headline: 'Reicht Ihre private Vorsorge aus?',
      paragraph:
        'Ihre privaten Versicherungen decken nicht das Risiko einer Betriebsunterbrechung. So sichert z. B. die  Berufs-unfähigkeitsversicherung Ihren Lebensstandard, wenn Sie nicht mehr in Ihrem Beruf arbeiten können – übernimmt aber keine Betriebskosten und keinen entgangenen Gewinn.',
    },
    halvedContent2: {
      headline: 'Die Lösung',
      paragraph:
        'Dafür gibt es die Existenz-Betriebsunterbrechungsversicherung (EBU) der NÜRNBERGER. Sie sorgt für die finanzielle Stabilität Ihres Unternehmens, wenn Sie ausfallen. Denn sie deckt laufende Fixkosten wie z. B.  Gehälter, Leasingraten oder Mieten/Pacht und zahlt  auch Ihren entgangenen Gewinn.',
    },
  },
  existenz: {
    headline:
      'Die Existenz-Betriebsunterbrechungsversicherung bietet maßgeschneiderten Schutz',
    cards: [
      {
        icon: HomeOfficeIcon,
        headline: 'Für Freiberufler',
        paragraph:
          'Ob Architekt, Arzt, Anwalt oder Unternehmensberater - die EBU deckt Ihre Kosten, wenn Sie nicht arbeiten können.',
      },
      {
        icon: MaintenanceIcon,
        headline: 'Für Handwerker & Händler',
        paragraph:
          'Gestalten Sie Ihre Versicherung nach Ihren persönlichen Anforderungen - passend für Ihr Geschäft.',
      },
    ],
    vorteileHeadline: 'Ihre Vorteile auf einen Blick',
    vorteile: [
      'Leistet bereits ab 70 % Arbeitsunfähigkeit nach Unfall oder Krankheit',
      'Sichert optional auch den entgangenen Gewinn ab.',
      'Freie Wahl der Karenztage - Zahlungen nach dem 21., 28., 42. oder für Freiberufler nach dem 90. Ausfalltag',
      'Schutz bis zum 65. Lebensjahr und europaweit für Aufenthalte bis zu 30 Tagen',
      'Ganz nach Wunsch laufen die Zahlungen 12 oder 24 Monate lang (Haftzeit)',
      'Führt eine Ersatzkraft Ihre Firma weiter, werden deren Kosten ebenfalls übernommen.',
    ],
  },
  liquidationsversicherung: {
    headline: 'Liquidationsversicherung',
    paragraphs: [
      '<strong>Unternehmensschließung trotz bAV</strong>',
      'In Deutschland wird jedes Jahr eine Vielzahl von Unternehmen aufgelöst, weil kein Nachfolger gefunden werden konnte.',
      'Oftmals scheitert eine Unternehmensauflösung an bestehenden Verpflichtungen aus der betrieblichen Altersversorgung (bAV).',
    ],
    card: {
      cardSections: {
        paragraphs: [
          'Denn solange eine Versorgungsverpflichtung besteht, kann das Unternehmen nicht endgültig aufgelöst werden. Bei lebenslangen Rentenzahlungen bedeutet dies, dass ein Unternehmen erst dann endgültig abgewickelt werden kann, nachdem der letzte Rentner verstorben ist. Dies führte häufig zu den Phänomenen der „Rentner-GmbHs“, deren einzig verbleibender Geschäftszweck die Abwicklung der Versorgungsverpflichtungen ist.',
          'Um zu vermeiden, dass Unternehmen ausschließlich wegen bestehender Versorgungszusagen weitergeführt werden müssen, besteht seit 2000 die Möglichkeit, Versorgungszusagen anlässlich der Liquidation eines Unternehmens (reguläre Beendigung der Unternehmenstätigkeit, keine Insolvenz) auf ein Unternehmen der Lebensversicherung (oder Pensionskasse) zu übertragen.',
        ],
        list: [
          'Sie als Arbeitgeber schließen für Ihren Mitarbeiter eine Lebensversicherung in Höhe seines Versorgungsversprechens ab. In einer Zusatzvereinbarung zum Versicherungsantrag werden die rechtlichen Rahmenbedingungen zur Ablösung der Versorgungszusage geregelt.',
          'Die Vereinbarung wird sowohl von Ihnen als Arbeitgeber als auch vom Arbeitnehmer unterzeichnet.',
          'Der Nachweis der Liquidation erfolgt durch den Handelsregisterauszug mit der Eintragung der Liquidation.',
          'Nach Abschluss und Zahlung des Einmalbeitrages wird der Vertrag auf Ihren Mitarbeiter umgeschrieben. Dieser darf den Vertrag weder abtreten noch beleihen, verpfänden oder rückkaufen.',
          'Im Versorgungsfall wird die Leistung direkt an Ihren Mitarbeiter bzw. dessen Hinterbliebene gezahlt. Die Auszahlung sowie deren Versteuerung und Verbeitrag in der Sozialversicherung übernimmt in der Regel Ihr Versicherungsanbieter.',
        ],
        notice: [
          '<strong>Hinweis:</strong> Die Liquidationsversicherung wird derzeit nicht von der NÜRNBERGER Versicherung angeboten.',
        ],
      },
    },
    footNote: {
      headline: 'Das sollten Sie steuerlich beachten:',
      paragraphs: [
        'Durch den Abschluss der Liquidationsversicherung werden Sie als Unternehmer von der Versorgungsverpflichtung befreit. Gegebenenfalls gebildete Pensionsrückstellungen sind daher gewinnerhöhend aufzulösen. Gleichzeitig stellt der Einmalbeitrag an die Versicherungsgesellschaft für Sie in voller Höhe eine Betriebsausgabe dar.',
      ],
    },
  },
};

export default content;
